import React from "react";

function FlowersImage({ className }) {
    return (
        <svg width="154" height="124" viewBox="0 0 154 124" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <g>
                <path
                    d="M147.068 73.0859H100.394H94.6257C91.0776 73.0859 88.1934 70.1457 88.1934 66.5805C88.1934 62.9921 91.1005 60.0751 94.6257 60.0751H109.276C112.824 60.0751 115.708 57.135 115.708 53.5698C115.708 49.9814 112.801 47.0644 109.276 47.0644H104.285C100.737 47.0644 97.8532 44.1243 97.8532 40.5591C97.8532 36.9707 100.76 34.0537 104.285 34.0537H106.323C109.871 34.0537 112.755 31.1135 112.755 27.5483C112.755 23.96 109.848 21.043 106.323 21.043H26.6867C23.1387 21.043 20.2545 23.9831 20.2545 27.5483C20.2545 31.1367 23.1616 34.0537 26.6867 34.0537H32.5925C36.1405 34.0537 39.0247 36.9938 39.0247 40.5591C39.0247 44.1474 36.1176 47.0644 32.5925 47.0644H6.95512C3.40709 47.0413 0.5 49.9583 0.5 53.5466C0.5 57.135 3.40709 60.052 6.93223 60.052H28.9071C32.4551 60.052 35.3393 62.9921 35.3393 66.5574C35.3393 70.1457 32.4322 73.0627 28.9071 73.0627H22.7953H19.9111C16.3631 73.0627 13.4789 76.0029 13.4789 79.5681C13.4789 83.1565 16.386 86.0734 19.9111 86.0734H53.9951C57.5431 86.0734 60.4273 89.0136 60.4273 92.5788C60.4273 96.1672 57.5202 99.0842 53.9951 99.0842H48.0893C44.5413 99.0842 41.6571 102.024 41.6571 105.59C41.6571 109.178 44.5642 112.095 48.0893 112.095H139.743C143.291 112.095 146.175 109.155 146.175 105.59C146.175 102.001 143.268 99.0842 139.743 99.0842H134.753C131.205 99.0842 128.32 96.144 128.32 92.5788C128.32 88.9904 131.228 86.0734 134.753 86.0734H147.045C150.593 86.0734 153.477 83.1333 153.477 79.5681C153.5 76.0029 150.593 73.0859 147.068 73.0859Z"
                    fill="url(#paint0_linear)"/>
                <path d="M35.9574 27.5022H27.1675" stroke="#D2E8F8" strokeWidth="20" strokeMiterlimit="10"
                      strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M145.145 79.5913H131.182" stroke="#D2E8F8" strokeWidth="20" strokeMiterlimit="10"
                      strokeLinecap="round" strokeLinejoin="round"/>
                <path
                    d="M138.163 10.0695C134.707 9.09713 134.272 8.58781 133.379 4.60588C133.265 4.05026 132.761 3.63354 132.189 3.63354C131.617 3.63354 131.113 4.02711 130.999 4.60588C130.129 8.58781 129.671 9.09713 126.215 10.0695C125.688 10.2084 125.322 10.7177 125.322 11.2733C125.322 11.8289 125.688 12.3151 126.215 12.4771C129.671 13.4495 130.106 13.9588 130.999 17.9407C131.113 18.4963 131.617 18.913 132.189 18.913C132.761 18.913 133.265 18.5195 133.379 17.9407C134.272 13.9588 134.707 13.4495 138.163 12.4771C138.69 12.3382 139.056 11.8289 139.056 11.2733C139.056 10.7177 138.69 10.2315 138.163 10.0695Z"
                    fill="#FFC92F"/>
                <path
                    d="M28.9301 106.307C26.5724 105.636 26.2748 105.289 25.6567 102.58C25.5652 102.186 25.2218 101.909 24.8327 101.909C24.4435 101.909 24.1002 102.186 24.0086 102.58C23.4135 105.289 23.093 105.636 20.7582 106.307C20.3919 106.4 20.1401 106.747 20.1401 107.118C20.1401 107.488 20.3919 107.835 20.7582 107.928C23.1159 108.599 23.4135 108.947 24.0086 111.655C24.1002 112.049 24.4435 112.327 24.8327 112.327C25.2218 112.327 25.5652 112.049 25.6567 111.655C26.2519 108.947 26.5724 108.599 28.9301 107.928C29.2963 107.835 29.5481 107.488 29.5481 107.118C29.5481 106.747 29.2963 106.4 28.9301 106.307Z"
                    fill="#FFC92F"/>
                <path
                    d="M131.182 64.1961C132.446 64.1961 133.471 63.1596 133.471 61.881C133.471 60.6024 132.446 59.5659 131.182 59.5659C129.917 59.5659 128.893 60.6024 128.893 61.881C128.893 63.1596 129.917 64.1961 131.182 64.1961Z"
                    fill="#FFC92F"/>
                <path
                    d="M73.4751 123.694C74.7393 123.694 75.7641 122.657 75.7641 121.379C75.7641 120.1 74.7393 119.063 73.4751 119.063C72.2109 119.063 71.186 120.1 71.186 121.379C71.186 122.657 72.2109 123.694 73.4751 123.694Z"
                    fill="#FFC92F"/>
                <path
                    d="M9.01512 39.2163C10.2793 39.2163 11.3042 38.1798 11.3042 36.9013C11.3042 35.6227 10.2793 34.5862 9.01512 34.5862C7.75092 34.5862 6.72607 35.6227 6.72607 36.9013C6.72607 38.1798 7.75092 39.2163 9.01512 39.2163Z"
                    fill="#FFC92F"/>
                <path
                    d="M57.6115 56.9035L75.1456 67.8538C76.7709 68.8725 77.2745 70.9792 76.2673 72.6229C75.2601 74.2897 73.1771 74.7759 71.5518 73.7573L54.0177 62.807C52.3696 61.7883 51.8889 59.6816 52.8961 58.0379C53.9033 56.371 55.9634 55.8849 57.6115 56.9035Z"
                    fill="#00CA74"/>
                <path
                    d="M25.9544 43.6613L32.5926 54.9589L41.0621 41.0684L28.1061 40.1192C26.252 40.0035 25.0159 42.0407 25.9544 43.6613Z"
                    fill="#FFB618"/>
                <path
                    d="M58.4358 61.4875C62.785 54.357 60.6104 45.0041 53.5373 40.6055L22.2231 52.2735C20.4147 52.9448 20.1629 55.4451 21.8111 56.4637L37.7886 66.4417C44.8389 70.8404 54.0866 68.6179 58.4358 61.4875Z"
                    fill="#FFD458"/>
                <path
                    d="M58.436 61.4873C54.0868 68.6178 44.839 70.8402 37.7888 66.4416L34.0576 32.8961C33.8516 30.9515 35.9346 29.6087 37.5828 30.6505L53.5603 40.6285C60.5877 45.004 62.7852 54.3569 58.436 61.4873Z"
                    fill="#FFC92F"/>
                <path
                    d="M37.5597 30.6274L53.3312 40.4665C60.3815 44.8651 62.7621 54.1949 58.4816 61.3485C56.8564 64.0571 54.5215 66.0944 51.8662 67.2982C52.5072 66.5806 53.1023 65.7703 53.6288 64.8905C57.8635 57.737 55.46 48.4535 48.4327 44.078L34.2863 35.2576L34.0117 32.873C33.8285 30.9515 35.9345 29.6088 37.5597 30.6274Z"
                    fill="#FFB618"/>
                <path
                    d="M102.774 36.2298L102.179 57.0655C102.133 59.0102 100.577 60.4918 98.6541 60.4455C96.7313 60.3992 95.2663 58.825 95.3121 56.8803L95.9072 36.0446C95.953 34.1 97.5096 32.6183 99.4324 32.6646C101.378 32.7109 102.843 34.2852 102.774 36.2298Z"
                    fill="#00CA74"/>
                <path
                    d="M98.2884 1.89735L91.9478 13.357L108.063 13.8431L102.386 2.0131C101.562 0.299943 99.204 0.230491 98.2884 1.89735Z"
                    fill="#FFB618"/>
                <path
                    d="M99.2498 39.2394C107.536 39.4941 114.449 32.8961 114.678 24.5155L89.0406 2.91589C87.5527 1.66574 85.3095 2.68438 85.2408 4.65219L84.6914 23.6358C84.4625 31.9932 90.9634 38.9848 99.2498 39.2394Z"
                    fill="#FFD458"/>
                <path
                    d="M99.2495 39.2395C90.9631 38.9848 84.4393 31.9933 84.6911 23.6358L111.565 3.61044C113.121 2.4529 115.319 3.61044 115.25 5.5551L114.701 24.5387C114.449 32.8962 107.536 39.4941 99.2495 39.2395Z"
                    fill="#FFC92F"/>
                <path
                    d="M115.25 5.53197L114.701 24.2609C114.449 32.6184 107.673 39.4015 99.3871 39.2163C96.2511 39.1469 93.344 38.1282 90.9634 36.3919C91.9019 36.6003 92.9091 36.716 93.9162 36.7392C102.157 36.8781 108.91 30.1181 109.161 21.7838L109.642 4.97635L111.542 3.54101C113.121 2.42977 115.296 3.58731 115.25 5.53197Z"
                    fill="#FFB618"/>
                <path
                    d="M123.468 86.1431L108.108 86.6292C106.803 86.6755 105.59 85.9578 104.972 84.8003C104.354 83.6428 104.446 82.2306 105.178 81.1656L114.014 68.4559C115.113 66.9048 117.241 66.5112 118.798 67.6224C120.354 68.7337 120.721 70.8867 119.622 72.4609L114.746 79.4756L123.239 79.221C125.139 79.1515 126.718 80.6795 126.787 82.5778C126.855 84.4762 125.367 86.0736 123.468 86.1431Z"
                    fill="#CA0015"/>
                <path
                    d="M106.392 80.1007L88.2622 89.9861C86.5683 90.9121 85.9503 92.9725 86.8659 94.6857L100.12 119.55C101.035 121.263 103.072 121.888 104.766 120.962L122.896 111.076C124.589 110.15 125.207 108.09 124.292 106.377L111.038 81.5129C110.123 79.7998 108.085 79.1747 106.392 80.1007Z"
                    fill="#F4F2E7"/>
                <path
                    d="M111.038 81.5129L124.292 106.377C125.207 108.09 124.589 110.15 122.895 111.076L113.831 116.008L97.3267 85.0318L106.391 80.1007C108.085 79.1747 110.122 79.7998 111.038 81.5129Z"
                    fill="#E9E5CF"/>
                <path
                    d="M75.9012 37.5265L85.6754 55.8619C86.5911 57.575 85.973 59.6354 84.2791 60.5615C82.5852 61.4875 80.548 60.8624 79.6323 59.1493L69.8581 40.837C68.9425 39.1239 69.5605 37.0634 71.2544 36.1374C72.9483 35.2114 74.9856 35.8133 75.9012 37.5265Z"
                    fill="#00CA74"/>
                <path
                    d="M107.215 45.6291L111.633 77.2299L109.642 86.2124L91.5124 96.0978L82.9971 92.8566L59.3283 71.7432C58.3898 71.0023 57.9549 69.8911 58.0922 68.7104C58.1838 67.5529 58.8247 66.5805 59.8548 66.0249L102.179 42.9668C103.209 42.4111 104.377 42.4111 105.384 42.9668C106.437 43.4529 107.124 44.4253 107.215 45.6291Z"
                    fill="#F4F2E7"/>
                <path
                    d="M100.577 91.1437L109.642 86.2125L111.633 77.23L107.216 45.6293C107.124 44.4254 106.437 43.4531 105.384 42.9206C104.377 42.365 103.21 42.365 102.18 42.9206L81.0288 54.4497L100.577 91.1437Z"
                    fill="#E9E5CF"/>
                <path
                    d="M97.5555 92.7872C96.1363 93.5512 94.3508 93.2503 93.275 91.9538L79.8841 75.5862C78.6709 74.1046 78.8769 71.9284 80.3419 70.7014C81.8069 69.4744 83.9586 69.6828 85.1718 71.1644L98.5627 87.532C99.7759 89.0137 99.5699 91.1898 98.1049 92.4168C97.9446 92.5557 97.7386 92.6946 97.5555 92.7872Z"
                    fill="#F9F8F3"/>
                <path d="M111.633 77.23L112.663 84.5688L88.4908 97.7416L82.9971 92.8336L111.633 77.23Z" fill="#CA0015"/>
                <path
                    d="M55.048 10.0696L55.2312 23.2192L69.4233 15.4869L58.6647 8.12494C57.1082 7.03686 55.0251 8.17125 55.048 10.0696Z"
                    fill="#FFB618"/>
                <path
                    d="M74.3447 41.925C81.6468 37.9431 84.3708 28.7522 80.4336 21.3671L47.54 15.6489C45.6401 15.3248 44.198 17.3389 45.1136 19.0752L54.018 35.79C57.9552 43.152 67.0427 45.9069 74.3447 41.925Z"
                    fill="#FFD458"/>
                <path
                    d="M74.3448 41.9251C67.0428 45.907 57.9552 43.1521 54.0181 35.767L67.3861 4.83758C68.1644 3.05497 70.6366 2.93922 71.5522 4.65238L80.4566 21.3672C84.3709 28.7523 81.6469 37.9432 74.3448 41.9251Z"
                    fill="#FFC92F"/>
                <path
                    d="M71.5292 4.65239L80.3192 21.1589C84.2334 28.5208 81.7155 37.8274 74.4592 41.8557C71.7123 43.3836 68.6908 43.9624 65.7837 43.6614C66.6993 43.3605 67.6149 42.9669 68.5077 42.4576C75.7182 38.4062 78.2132 29.1459 74.2989 21.8071L66.4246 7.03691L67.3631 4.83759C68.1414 3.03183 70.6136 2.93923 71.5292 4.65239Z"
                    fill="#FFB618"/>
            </g>
            <defs>
                <linearGradient id="paint0_linear" x1="0.5" y1="66.5726" x2="153.495" y2="66.5726"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#BADCF5"/>
                    <stop offset="1" stopColor="#81C9F5"/>
                </linearGradient>
                <clipPath id="clip0">
                    <rect width="153" height="123" fill="white" transform="translate(0.5 0.693604)"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default FlowersImage;
