import React from "react";
import ButtonNextSlider from "src/components/button-next-slider";
import homeClasses from "src/pages/home.module.scss";
import SliderCard from "src/components/slider-card";
import FlowersImage from "src/components/slider-images/flowers-image";
import TrolleyImage from "src/components/slider-images/trolley-image";
import PackagesImage from "src/components/slider-images/packages-image";
import PhoneIcon from "src/components/contact-icons/phone";
import LatterIcon from "src/components/contact-icons/latter";
import DialogIcon from "src/components/contact-icons/dialog";
import DulaProfile from "../image/ĎulaDusič.jpg"
import JurajBéProfile from "../image/JurajBé.jpg"
import MariVircikProfile from "../image/MariVircik.jpg"
import MarošZeleňákProfile from "../image/MarošZeleňák.jpg"
import PetronelaBodnárováProfile from "../image/PetronelaBodnárová.jpg"
import RadovanKubovičProfile from "../image/RadovanKubovič.jpg"
import {getRandomNumber} from "src/helpers/additionalFuctions";
import Underline from "../components/undreline";
import {openMessangerWindow} from "../services/messanger-facebook.service";
import PrivacyPdf from "../resources/GDPR-doruč.to_.pdf";
import BusinessConditions from "../resources/obchodne-podmienky.pdf";

import {Link} from "gatsby";

export const sliderItems = [

    <SliderCard
        image={<TrolleyImage className={homeClasses.sliderImage}/>}
        title={"Lieky"}
        description={"Skočíme do lekárne a doručíme Vám lieky"}
    />,
    <SliderCard
        image={<PackagesImage className={homeClasses.sliderImage}/>}
        title={"Potraviny"}
        description={"Nakúpime a doručíme Váš nákup"}
    />,
    <SliderCard
        image={<FlowersImage className={homeClasses.sliderImage}/>}
        title={"Donáška kvetov"}
        description={"Doručíme Vášmu blízkemu kyticu"}
    />,
];
export const responsive = {
    500: {
        items: 1,
    },
    672: {
        items: 3,
    },
};
export const optionsSlider = {
    renderNextButton: ({isDisabled}) => (
        <div className={homeClasses.buttonSlider}>
            <ButtonNextSlider/>
        </div>
    ),
};
export const listPriceCards = [
    {
        navigateTo: '/delivery-ke-cz',
        goodOptions: {
            title: "Z Košíc do Českej republiky",
            cost: 7,
            description: {
                text: "S vyzdvihnutím do 10:00 a&nbsp;doručením na ďalší pracovný deň"
            },
            direction: 1,
        },
        descriptionOptions: {
            placeTitle: "Príplatková zóna vyzdvihnutia +5€",
            places: [
                "Baška",
                "Krásna"
            ],
            optionsTitle: "Príplatkové služby",
            options: [
                {
                    text: "Neskoršie vyzdvihnutie (do 14:00)",
                    additionCost: 2,
                },

                {
                    text: "Víkendové vyzdvihnutie",
                    additionCost: 5,
                    additionalText: "S odoslaním z Košíc v nasledujúci pracovný deň"
                }
            ]
        },
    },
    {
        navigateTo: '/delivery-ke-ke',
        goodOptions: {
            title: "V rámci Košíc",
            cost: 7,
            description: {
                text: "Štandard s doručením v rovnaký deň v mestskej zóne",
                link: "#",
                textLink: "zobraziť zónu",
            },
            direction: 2,
        },
        descriptionOptions: {
            placeTitle: "Príplatková zóna +3€",
            places: [
                "Baška",
                "Krásna"
            ],
            optionsTitle: "Príplatkové služby",
            options: [
                {
                    text: "Expres preprava do 60 minút alebo\n v konkrétnom" + " časovom okne",
                    additionCost: 5,
                },
                {
                    text: "Víkendová preprava",
                    additionCost: 5,
                },
                {
                    text: "Vyzdvihnutie balíka vo výdajnom mieste s čakaním, vyzdvihnutie liekov, kytice",
                    additionCost: 5,
                },
                {
                    text: "Nákup (max. 5 položiek na 1 adrese)",
                    additionCost: 10,
                }
            ]
        },
    },
];
export const faqButtons = [
    {
        text: "Z Košíc do Českej republiky",
        id: 1,
        active: false
    },
    {
        text: "V rámci Košíc",
        active: true,
        id: 2
    },
    {
        text: "Pre firmy",
        id: 3,
        active: false
    }
];

export const QUESTIONS_KE_CZ = [
    {
        title: "Ako to funguje?",
        body: <p>Zásielky vyzdvihnuté do 14:00 sú doručované kdekoľvek v ČR vždy nasledujúci pracovný deň. Spoluracujeme
            s kuriérskou službou PPL, ktorá zásielky rozváža v ČR</p>,
    },
    {
        title: "Ako si objednám?",
        body: <p>Po vytvorení objednávky Vám e-mailom bude zaslaný vygenerovaný štítok, ktorý vytlačte a nalepte na balík. Kuriér o Vašom balíku vie a bude Vás telefonicky kontaktovať pred jeho vyzdvihnutím na adrese, ktorú ste zadali do objednávky.</p>,
    },
    {
        title: "Ako zaplatím za dopravu?",
        body: <p>Platiť sa dá kuriérovi v hotovosti alebo platobnou kartou pri odovzdávaní zásielky.</p>,
    },
    {
        title: "Aké max. rozmery môže mať zásielka?",
        body: <p>Zásielka by nemala byť väčšia než 30 x 30 x 30 cm a jej váha nesmie presiahnuť 5kg.</p>,
    },
    {
        title: "Aké zásielky neprepravujete?",
        body: <p>Z prepravy sú vylúčené zásielky potraviného charakteru, ktoré podliehajú trvanlivým teplotám uskladnenia, ďalej zásielky krehké, sklenené, zásielky nepravidelného tvaru, nadrozmerné zásielky, živé zvieratá, drahé kovy a ceniny.</p>,
    },
    {
        title: "Musím zásielku zabaliť?",
        body: <p>Zásielku je nutné zabaliť do kartónovej krabice, resp. do krabice s pravidelnými rozmermi.</p>,
    },
    {
        title: "Musím tlačiť štítok?",
        body: <p>Nie, zásielku oštítkujeme za Vás</p>,
    },
]

export const QUESTIONS_KE_KE = [
    {
        title: "Na čo využijem doruč.to?",
        body: <p>Pôjdeme za Vás na nákup, do lekárne, skočíme do čistiarne, podáme balíky na pošte alebo dovezieme
            kyticu pre partnerku. Sme tu pre Vás, keď to budete potrebovať.</p>,
    },
    {
        title: "Ako si objednám?",
        body: <p>Jednoducho nám napíšte správu cez FB Messenger.</p>,
    },
    {
        title: "Aké veľké zásielky prepravujete?",
        body: <p>Prepravíme čokoľvek do rozmeru 2 x 4 m.</p>,
    },
    {
        title: "Kedy môžem objednávať?",
        body: <p>Naše služby využijete v Košiciach v pracovné dni od 09:00 do 18:00, cez víkendy podľa dohody.</p>,
    },
    {
        title: "Spolupracujete aj s e-shopmi?",
        body: <p>Áno, pre našich partnerov zabezpečujeme kompletné logistické služby s doručením už v deň objednávky.
            Viac sa dozviete na  <Link to="/for-companies">www.doruc.to/spolupraca</Link></p>,
    },

    {
        title: "Kedy mi bude doručená moja objednávka z e-shopu?",
        body: <p>Zásielky vyzdvihujeme u našich partnerských e-shopoch do 15:30 a doručujeme do 20:00.</p>,
    },
    {
        title: "Koľko to trvá?",
        body: <p>Vybaviť Vaše objednávky sme schopní už do 60 minút, ale čas záleží aj od náročnosti Vašej
            požiadavky.</p>,
    },
    {
        title: "Koľko zaplatím?",
        body: <p>Cenu viete vždy dopredu a bez Vášho súhlasu kuriér nevyrazí. Snažíme sa zabezpečiť najlepšie ceny
            zodpovedajúcej hodnote, ktorú prinášame.</p>,
    },
    {
        title: "Viem kuriéra objednať na presný čas?",
        body: <p>Samozrejme, po dohode doručujeme aj na presný čas.</p>,
    },
    {
        title: "Ako Vám zaplatím?",
        body: <p>Platíte kuriérovi v hotovosti alebo platobnou kartou.</p>,
    },
    {
        title: "Sú moje údaje chránené?",
        body: <p>Bezpečnosť Vašich osobných údajov je pre nás dôležitá. Viac o tom ako chránime Vaše osobné údaje sa
            dozviete v našich obchodných podmienkach.</p>,
    },
    {
        title: "Máte k dispozícii API napojenie?",
        body: <p>Ak máte akýkoľvek iný dotaz, kontaktujte nás na <a href="mailto:info@doruc.to">info@doruc.to</a>, radi
            zodpovieme Vaše otázky.</p>,
    }


];

export const contacts = [
    {
        title: "Zavolajte nám",
        image: <PhoneIcon/>,
        description: "+421 907 903 798",
        href: 'tel:+421907903798'
    },
    {
        title: "Napíšte nám email",
        image: <LatterIcon/>,
        description: "info@doruc.to",
        href: 'mailto:info@doruc.to'
    },
    {
        title: "Napíšte nám do chatu",
        image: <DialogIcon/>,
        description: 'Spustiť chat',
        onClick: openMessangerWindow
    }
];
export const MODALS = {
    ADDING_MANUALY_PICKUP: 'ADDING_MANUALY_PICKUP',
    ADDING_MANUALY_DELIVERY: 'ADDING_MANUALY_DELIVERY',
    COOKIE_SETTING: {
        manualKeKeDelivery: "MANUAL_ADDRESS_KE_KE_DELIVERY",
        manualKeKePickUp: "MANUAL_ADDRESS_KE_KE_PICKUP",
        manualKeCzDelivery: "MANUAL_ADDRESS_KE_CZ_DELIVERY",
        manualKeCzPickup: "MANUAL_ADDRESS_KE_CZ_PICKUP"
    },
};

export const ALLOWED_MANUALY_ZIPS = [
    '04001',
    '04011',
    '04012',
    '04013',
    '04014',
    '04015',
    '04016',
    '04017',
    '04018',
    '04022',
    '04023'
];

export const PRIPADKOVA_ZONA = ['040 15', '040 18', '040 16', '040 12']


export const ADDRESSES = [
    {value: 'Budapeštianska, Košice'},
    {value: 'Budapeštianska, Košice'},
    {value: 'Budapeštianska, Košice'},
    {value: 'Budapeštianska, Košice'},
    {value: 'Budapeštianska, Košice'},
];


export const allPlaceForSearchingForPickup = [
    'Šaca',
    'Poľov',
    'Krásna',
    'Myslava',
    'Baška',
    "Šebastovce",
    "Pereš",
    "Lorinčík",
    "Kavečany",
    'Nad jazerom',
    'Košice'
]
export const INPUT_OPTIONS = {
    shadow: true,
};

export const FEEDBACKS = [
    {
        message: "Výborná služba. Ďakujem za veľmi profesionálne vybavenie požiadavky a potešenie môjho blízkeho nádhernou kyticou.👏👏 Vrelo odporúčam.",
        from: "Košice",
        to: "Košice",
        client: {
            name: "Juraj Bé",
            logo: JurajBéProfile,
        }
    },
    {
        message: "Parádna služba, vždy načas a rýchla komunikácia :) nečakal som doručenie aj v nedeľu, bol som nesmierne prekvapený. Good luck a teším sa aj nabudúce! 🙌",
        from: "Košice",
        to: "Košice",
        client: {
            name: "Radovan Kubovič",
            logo: RadovanKubovičProfile,
        }
    },
    {
        message: "Super! Top komunikacia, bezproblemove dorucenie. Vdaka! Som rada, ze taku sluzbu mame v KE, usetri vela casu",
        from: "Košice",
        to: "Košice",
        client: {
            name: "Mari Vircik",
            logo: MariVircikProfile,
        }
    },
    {
        message: "Dnes som 1x využila služby Doruč.to a som nadšená. Úžasná komunikácia, veľmi milý kuriér. Oceňujem doručenie aj v nedeľu a spätnú väzbu o doručení 🤩",
        from: "Košice",
        to: "Košice",
        client: {
            name: "Petronela Bodnárová",
            logo: PetronelaBodnárováProfile,
        }
    },
    {
        message: "Doruc.to som vyuzil viac krat na prepravu tovaru v ramci Kosic a sluzbu mozem len odporucit. Velmi promptna, jasna komunikacia a rychle dorucenie.",
        from: "Košice",
        to: "Košice",
        client: {
            name: "Maroš Zeleňák",
            logo: MarošZeleňákProfile,
        }
    },
    {
        message: "Spoľahlivosť, flexibilita, ochotní a slušní kuriéri - profesionáli. Už aj prehľadný a jednoduchý online systém pre odosielateľov. Vynikajúca služba.",
        from: "Košice",
        to: "Košice",
        client: {
            name: "Ďula Dusič",
            logo: DulaProfile,
        }
    },
];

export const feedback = FEEDBACKS[getRandomNumber(FEEDBACKS.length)];

export const TYPES_FORM = {
    KE_KE: "KE_KE",
    KE_CZ: "KE_CZ",
};


export const MESSAGE_EXTRA_PRICE = {
    express: "Express",
    pripadkovaZoneDelivery: "Adresa v príplatkovej zóne",
    pripadkovaZonePickup: "Adresa v príplatkovej zóne",
    dayOff: "Víkendové doručenie",
    extraTime: "Príplatok"

}

export const METHOD_PAYMENT = {
    1: 'Kuriérovi pri vyzdvihnutí (kartou alebo v hotovosti)',
    2: 'Kuriérovi pri doručení (kartou alebo v hotovosti)'
}
export const IOS_CODE = {
    CzechRepublic: 'CZE',
    Slovakia: "SVK"
}
export const DESTINATIONS = {
    city: {
        košice: 'Košice',
        lat: 48.71493197465295,
        lng: 21.2591103448377338,
        radiusSearching: 16000,
        types: 'address'
    },
    country: {
        CzechRepublic: "Czechia",
        Slovakia: "Slovensko",
    }
}

export const INFO_PACKAGE = {
    ke_ke: {
        maxSize: '100 x 100 x 100 cm',
        maxWeight: '40 kg'
    },
    ke_cz: {
        maxSize: '20 x 20 x 20 cm',
        maxWeight: '5 kg'
    }
}

export const HOUR_TO_PICKUP = 18;
export const ACCEPTED_COOKIES = true;
export const KEY_ACCEPTED_COOKIES = 'cookieIsAccepted';
export const KEY_MANUAL_ADRESS = 'manualAddress';
export const PRIVACY_POLICY = PrivacyPdf;
export const BUSINESS_CONDITIONS = BusinessConditions;
export const CHAT_LINK = 'https://www.messenger.com/login.php?next=https%3A%2F%2Fwww.messenger.com%2Ft%2F133255100720535%2F%3Fmessaging_source%3Dsource%253Apages%253Amessage_shortlink';
