import React from "react";

function PhoneIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            fill="none"
            viewBox="0 0 36 36"
        >
            <path
                fill="#F46032"
                d="M26.48 35.645c-.65-.11-1.306-.195-1.953-.333a25.408 25.408 0 01-6.86-2.81 36.65 36.65 0 01-9.032-7.073c-3.19-3.465-5.842-7.282-7.516-11.722A15.255 15.255 0 01.017 8.69 6.037 6.037 0 011.67 4.075c.998-1.022 1.963-2.082 3.038-3.014 1.659-1.426 3.465-1.426 5.067.067a100.322 100.322 0 014.901 4.953 3.366 3.366 0 010 4.578c-.836.951-1.763 1.816-2.648 2.724-.08.076-.166.143-.313.276a25.794 25.794 0 0010.168 10.14l2.11-2.135a5.328 5.328 0 011.902-1.426 3.19 3.19 0 013.475.618 138.265 138.265 0 015.182 5.2 3.394 3.394 0 01.09 4.531c-1.065 1.255-2.244 2.444-3.437 3.594a5.296 5.296 0 01-3.08 1.374c-.082.02-.163.043-.243.071l-1.402.02zm.529-2.144a4.074 4.074 0 003.16-1.245c.8-.837 1.617-1.655 2.425-2.482.105-.105.203-.216.295-.332a1.454 1.454 0 00-.043-2.092c-.651-.704-1.34-1.37-2.015-2.05-.88-.878-1.75-1.763-2.639-2.628a1.427 1.427 0 00-2.077-.057c-.133.11-.252.233-.376.357-.846.841-1.683 1.692-2.534 2.524a1.611 1.611 0 01-2.02.337 26.23 26.23 0 01-1.53-.817 26.356 26.356 0 01-5.905-4.925 21.282 21.282 0 01-3.907-5.553c-.504-1.112-.395-1.654.475-2.5.87-.846 1.802-1.745 2.662-2.657a1.358 1.358 0 00.033-1.992c-.104-.124-.214-.238-.323-.352L8.516 2.844c-.893-.88-1.687-.889-2.581 0-.894.89-1.735 1.673-2.548 2.562-.4.414-.723.897-.95 1.427a6.056 6.056 0 00-.044 3.493 21.158 21.158 0 001.854 5.11 34.902 34.902 0 005.4 7.85 34.061 34.061 0 0013.635 9.412 12.42 12.42 0 003.727.784v.02z"
            ></path>
        </svg>
    );
}

export default PhoneIcon;
