import React from "react";

function LatterIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="49"
            height="34"
            fill="none"
            viewBox="0 0 49 34"
        >
            <path
                fill="#F46032"
                d="M4.591 0A4.607 4.607 0 000 4.591v24.104a4.607 4.607 0 004.591 4.59h39.025a4.607 4.607 0 004.59-4.59V4.59A4.607 4.607 0 0043.617 0H4.59zm0 2.296h39.025c.194 0 .377.028.556.071L25.018 20.893c-.422.409-1.407.409-1.83 0L4.036 2.367c.179-.043.362-.071.556-.071zM2.385 3.999L15.8 16.966 2.547 29.716a2.296 2.296 0 01-.251-1.021V4.59c0-.208.04-.401.09-.592zm43.437 0c.049.19.09.384.09.592v24.104c0 .374-.1.713-.252 1.022L32.407 16.966 45.822 3.999zM17.45 18.562l4.125 3.981a3.655 3.655 0 005.057 0l4.125-3.981L43.67 30.99H4.537L17.45 18.562z"
            ></path>
        </svg>
    );
}

export default LatterIcon;
