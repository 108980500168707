import React from "react";
import classNames from "classnames";
import classes from "./close-icon.module.scss";
function CloseIcon({ size = "big", ...props}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
            {...props}
            className={classNames({[`${classes.small}`]: size === "small"})}
        >
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M18 6L6 18M6 6l12 12"
            ></path>
        </svg>
    );
}

export default CloseIcon;
