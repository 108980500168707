import moment from 'moment';
import { geocodeByPlaceId } from 'react-places-autocomplete';
import {TIME_ITEMS, TIME_WINDOWS, today, START_WORKING_HOUR, KE_CZ_TIME_ITEMS} from "src/constants/calendars";
import TimeItemFactory from "../models/factories/time-item-factory";
import {HOUR_TO_PICKUP, MESSAGE_EXTRA_PRICE, TYPES_FORM, METHOD_PAYMENT} from "../constants/homepage";
moment.suppressDeprecationWarnings = true;

export const toCamelCase = function (string, separator = "-") {
    if(!string) {
        return string;
    }
    string = string.split(separator);
    let newString = '';
    for(let i = 0; i < string.length; i++) {

        let substring = string[i];
        if(i !== 0) {
            substring = substring.charAt(0).toUpperCase() + substring.slice(1);
        }
        newString += substring;
    }
    return newString;
};

export const getRandomNumber = (max) => Math.floor(Math.random() * Math.floor(max));

export const toDropdownList = (suggestions, Locality) => {
     const list = [];
     const {locality} = Locality
     suggestions.forEach( address => {
             for (let i = 0; i < locality.length; i++) {
                 if(address.description.includes(locality[i])){
                     list.push({
                        description: address.description,
                        placeId: address.placeId,
                     })
                 }
             }
     });

     return list;
};

export const getPlace = (placeId) => {
    return new Promise((resolve, reject) => {
        geocodeByPlaceId(placeId)
            .then(address => resolve(parseAddress(address[0])))
            .catch(error => reject(error));
    });
};



export const extractAddressComponent = (place, name) => {
    if (!place || !place.address_components) {
        return null;
    }
    const obj = place.address_components.find(c => {
        return c.types.indexOf(name) > -1;
    });
    if (obj && obj.long_name) {
        return obj.long_name;
    }
    return null;
};

export const parseAddress = (address) => {
    let route = extractAddressComponent(address, 'route');
    let streetNumber = extractAddressComponent(address, 'street_number');
    if (!(route === 'null') && !(streetNumber === 'null')) {
        route = address.formatted_address.split(',')[0];
        streetNumber = '';
    }
    return {
        street: `${route} ${streetNumber}`,
        city:
            extractAddressComponent(address, 'locality') ||
            extractAddressComponent(address, 'sublocality') || 'Kosice city',
        postcode: extractAddressComponent(address, 'postal_code') || '04001',
        country: extractAddressComponent(address, 'country'),
        latitude: address.geometry.location.lat(),
        longitude: address.geometry.location.lng()
    };
};

export const getStringAddress = address => {
    return `${address.street}, ${address.city}, ${address.postcode}, ${address.country}`
};

export const getTimeItems = (isFiltered = false) => {
    return removeDuplicate(
        TIME_ITEMS
            .filter(item => !isFiltered || item.data.hour > getHours())
            .map(value => TimeItemFactory.create(value, isFiltered))
    );
};

export const removeDuplicate = (items) => {
  const times = [];
  return items.filter( item => {
      const timeLabel = item.getLabel();
      if(times.indexOf(timeLabel) < 0) {
          if(!item.itemCost && items.find( e => e.itemCost && e.getLabel() === timeLabel)) {
              return false;
          }
          times.push(timeLabel);
          return true;
      }
      return false;
  })
};

export const formatDate = (date = today, pattern = "DD.MM.yy") => {
    return moment(new Date(date)).format(pattern);
};

export const addDaysToDate = (dateToAdd, date = today) => {
    return moment(new Date(date)).add(dateToAdd, 'days');
};

export const getNextWeekDay = (neededDay, next = false, selectedDay=today) => {
    if (moment().day() <= neededDay && moment().day() !== 0 && !next) {
        return moment().isoWeekday(neededDay);
    }
    return moment(new Date(selectedDay)).add(1, 'weeks').isoWeekday(neededDay);
};

 

export const upperCaseFirst =(str)=>{
    return str.charAt(0).toUpperCase() + str.slice(1)
}


export const getHours = () => {
    return moment().toDate().getHours();
};

export const _getFormatPrice = (cost, isNeededMultiply = false)=>{
  let upDatedCost = isNeededMultiply ? cost * 100 : cost
    return (upDatedCost/100).toFixed(2).replace(".", ",")
}

export const getDayNumber = (date = today) => {
    return moment(new Date(date)).day();
};

export const getHumanMinutesFromTime = (time) => {
    if (time.getMinutes() < 10) {
        return `0${time.getMinutes()}`
    }

    return time.getMinutes()
}

export const isActivePickupDeliveryTime = () => {
    const time = new Date()
    if (HOUR_TO_PICKUP > time.getHours() && time.getHours() > START_WORKING_HOUR - 1) {
        return true
    } else {
        return false
    }
   
}


export const isItAWeekEndDay = () => (getDayNumber() === 6 || getDayNumber() === 0);

export const momentToDate = (defaultDay) => (new Date(defaultDay?.format('MM/DD/YYYY')));

export const getFilteredTimeWindows = (isFiltered) => {
    return TIME_WINDOWS
      .filter(item => (!isFiltered || item.data.hour > getHours()))
};

export const changeSpaceAtPostCode = (regex, postCode) => {
    return postCode.replace(regex, "$& ")
}


export const typeFormFromLocation = (pathName) => {
    return pathName.replace("/", "").toUpperCase().replace("-", "_")
}

export const putTime= (hour,minutes=0) => {
    return moment().set("hour", hour).set("minutes", minutes).format("HH:mm")
}




export const determineTimeDelivery = (
    deliveryDate,
    fromTypeForm,
    pickUpLableTime,
    deliveryTime
  ) => {
    const today = moment().format("L");
    const pickupDate = moment(new Date(deliveryDate)).format("L");
    const datatimeKeCz =
      !pickUpLableTime && deliveryTime
        ? KE_CZ_TIME_ITEMS.find((el) => el.id === deliveryTime.id)
        : null;
    const pickupTimeKeKe = pickUpLableTime || false;

    if (moment( new Date(today) ).isSame(pickupDate)) {
      const timeOfDelivery = `Dnes medzi ${
        datatimeKeCz ? datatimeKeCz?.time : pickupTimeKeKe
      }`;
      return timeOfDelivery;
    }
    return `${moment(new Date(deliveryDate)).format("DD.MM.YYYY")} medzi ${
      datatimeKeCz ? datatimeKeCz.time : pickupTimeKeKe
    }`;
  };
  export const createAditionalPrice = (additionalPrice) => {
    if (additionalPrice) {
      return Object.keys(additionalPrice)
        .filter((priceKey) => additionalPrice[priceKey].active)
        .map((priceKey) => ({
          __html: `${MESSAGE_EXTRA_PRICE[priceKey]} + ${_getFormatPrice(
            additionalPrice[priceKey].extraprice,
            true
          )} €`,
        }));
    }
    return [];
  };
