import React from "react";
import classes from "./time-window-option.module.scss";
import classNames from "classnames";

const TimeWindowOption = function ({ children, cost, free = null, description = null, weight = "normal" }) {

    
    return (
        <span className={classNames(
            classes["option"],
            classes[weight],
            "option-item"
        )}>
            <span>
                {children}
                {description && <p className={classes.description}>{description}</p>}
            </span>
            <span className={classes.free}>
                {free}
            </span>
            { cost && <span className={classes["cost"]}>+{cost} €</span> }
        </span>
    );
};

export default TimeWindowOption;
