import React from "react";

function DialogIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="43"
            height="34"
            fill="none"
            viewBox="0 0 43 34"
        >
            <path
                fill="#F46032"
                fillRule="evenodd"
                d="M26.284 3.105H15.983c-3.56 0-6.818 1.475-9.166 3.88C4.47 9.393 3.03 12.73 3.03 16.379c0 3.648 1.44 6.986 3.787 9.392 2.348 2.407 5.605 3.959 9.166 3.959h10.301c.833 0 1.667-.078 2.424-.233.833-.155 1.59-.388 2.348-.698l.379-.156.454.078c.076 0 .303.078.606.155.303 0 .682.078 1.364.31l2.651.544 2.651.621.227.078c0-.078-.075-.233-.075-.31l-.758-2.64-.833-2.717c-.076-.233-.151-.31-.151-.31 0-.233 0 0-.076-.388l-.152-.621.303-.544c.53-1.009.91-2.018 1.212-3.105.303-1.086.455-2.25.455-3.415 0-3.648-1.515-6.986-3.864-9.392-2.348-2.406-5.605-3.881-9.165-3.881zM15.983 0h10.301c4.393 0 8.408 1.863 11.362 4.813a16.58 16.58 0 014.697 11.565c0 1.475-.228 2.794-.53 4.192-.304 1.086-.758 2.25-1.213 3.26v.077l.834 2.717.757 2.562c.151.465.303.931.379 1.32.454 1.319.681 2.017 0 2.871-.758.854-1.44.699-2.954.31-.303-.077-.758-.232-1.06-.31l-2.728-.62-2.65-.622c-.304-.077-.834-.155-1.213-.233-.076 0-.227 0-.303-.077-.757.31-1.59.543-2.424.698-.909.156-1.97.31-2.954.31H15.983c-4.394 0-8.408-1.862-11.287-4.812C1.818 24.994 0 20.881 0 16.378A16.58 16.58 0 014.696 4.813C7.575 1.863 11.59 0 15.983 0zm24.239 31.359v.078l.075-.078c.076-.078.076-.078.076-.155l-.151.077v.078zm0-.078l.151-.077c.076-.078 0 0-.151.077zm0 .078c-.076.078-.076.155 0 .078v-.078z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default DialogIcon;
