import React from "react";
import cardClasses from "./card.module.scss";

const SliderCard = function ({ image, title, description }) {
    return (
        <div className={cardClasses['card']}>
            <div className={cardClasses['image']}>{image}</div>
            <h3 className={cardClasses['title']}>{title}</h3>
            <p className={cardClasses['description']}>{description}</p>
        </div>
    );
};

export default SliderCard;
