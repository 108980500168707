import React from "react";

function TrolleyImage({ className }) {
    return (
        <svg width="154" height="124" viewBox="0 0 154 124" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M144.054 46.6634H137.837C133.88 46.6634 130.651 49.8696 130.651 53.7973V55.0798C130.651 59.0075 133.88 62.2137 137.837 62.2137H145.749C150.028 62.2137 153.54 65.7005 153.54 69.9488V70.029C153.54 74.2773 150.028 77.7641 145.749 77.7641H114.059C110.103 77.7641 106.873 80.9703 106.873 84.898V86.1805C106.873 90.1081 110.103 93.3144 114.059 93.3144H120.599C124.878 93.3144 128.39 96.8012 128.39 101.049V101.13C128.39 105.378 124.878 108.865 120.599 108.865H111.112H89.7164H28.5971C24.3179 108.865 20.8058 105.378 20.8058 101.13V101.049C20.8058 96.8012 24.3179 93.3144 28.5971 93.3144H34.2892C38.2454 93.3144 41.4749 90.1081 41.4749 86.1805V84.898C41.4749 80.9703 38.2454 77.7641 34.2892 77.7641H8.29129C4.01214 77.724 0.5 74.2773 0.5 70.029V69.9488C0.5 65.7005 4.01214 62.2137 8.29129 62.2137H39.4161C43.3723 62.2137 46.6019 59.0075 46.6019 55.0798V53.7973C46.6019 49.8696 43.3723 46.6634 39.4161 46.6634H31.2615C26.9823 46.6634 23.5106 43.1766 23.5106 38.9283V38.8481C23.5106 34.5998 27.0227 31.113 31.3019 31.113H144.054C148.333 31.113 151.845 34.5998 151.845 38.8481V38.9283C151.804 43.1766 148.292 46.6634 144.054 46.6634Z" fill="url(#paint0_linear)"/>
            <path d="M105.259 8.98979C109.78 7.70729 110.386 7.06604 111.516 1.93603C111.677 1.21462 112.323 0.693604 113.09 0.693604C113.857 0.693604 114.503 1.21462 114.665 1.93603C115.835 7.06604 116.401 7.70729 120.922 8.98979C121.608 9.19018 122.093 9.83143 122.093 10.5128C122.093 11.2342 121.608 11.8754 120.922 12.0357C116.401 13.3182 115.795 13.9595 114.665 19.0895C114.503 19.8109 113.857 20.3319 113.09 20.3319C112.323 20.3319 111.677 19.8109 111.516 19.0895C110.345 13.9595 109.78 13.3182 105.259 12.0357C104.572 11.8353 104.088 11.1941 104.088 10.5128C104.088 9.83143 104.572 9.19018 105.259 8.98979Z" fill="#FFC92F"/>
            <path d="M6.23265 30.3113C8.45297 29.7102 8.73555 29.3895 9.30073 26.8646C9.38146 26.5039 9.70442 26.2634 10.0677 26.2634C10.4311 26.2634 10.754 26.5039 10.8348 26.8646C11.3999 29.3895 11.6825 29.7102 13.9028 30.3113C14.2258 30.3915 14.468 30.7121 14.468 31.0728C14.468 31.4335 14.2258 31.7141 13.9028 31.8343C11.6825 32.4355 11.3999 32.7561 10.8348 35.281C10.754 35.6417 10.4311 35.8822 10.0677 35.8822C9.70442 35.8822 9.38146 35.6417 9.30073 35.281C8.73555 32.7561 8.45297 32.4355 6.23265 31.8343C5.9097 31.7541 5.66748 31.4335 5.66748 31.0728C5.66748 30.7121 5.9097 30.3915 6.23265 30.3113Z" fill="#FFC92F"/>
            <path d="M111.233 123.694C113.061 123.694 114.543 122.222 114.543 120.407C114.543 118.592 113.061 117.121 111.233 117.121C109.405 117.121 107.923 118.592 107.923 120.407C107.923 122.222 109.405 123.694 111.233 123.694Z" fill="#FFC92F"/>
            <path d="M116.24 46.2542L66.6062 64.0222L78.8069 97.6143L128.441 79.8463L116.24 46.2542Z" fill="#E9E5CF"/>
            <path d="M101.827 68.7464L95.6913 66.8628L92.1791 72.1932L86.6082 56.8833L96.2564 53.3965L101.827 68.7464Z" fill="#E84C1C"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M78.4548 72.0375C79.4926 71.6592 80.6405 72.1939 81.0188 73.2316L86.3071 87.74C86.6854 88.7777 86.1508 89.9257 85.113 90.3039C84.0752 90.6822 82.9273 90.1476 82.549 89.1098L77.2606 74.6015C76.8824 73.5637 77.417 72.4158 78.4548 72.0375Z" fill="#F1EEE0"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M109.938 53.5632C110.975 53.1822 112.124 53.7139 112.505 54.7507L114.846 61.1231C115.227 62.1599 114.696 63.3093 113.659 63.6902C112.622 64.0712 111.473 63.5395 111.092 62.5027L108.75 56.1303C108.37 55.0935 108.901 53.9441 109.938 53.5632Z" fill="#F1EEE0"/>
            <path d="M96.4188 17.2848L54.9875 32.1165L65.5451 61.1844L106.976 46.3527L96.4188 17.2848Z" fill="white"/>
            <path d="M85.1143 35.5616L78.9782 33.678L75.466 39.0084L70.9043 26.4238L80.5526 22.937L85.1143 35.5616Z" fill="#E84C1C"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M67.5992 42.0976C68.6377 41.7215 69.7846 42.2584 70.1608 43.297L73.1077 51.4328C73.4839 52.4714 72.947 53.6182 71.9084 53.9944C70.8699 54.3706 69.723 53.8336 69.3469 52.7951L66.3999 44.6592C66.0237 43.6207 66.5607 42.4738 67.5992 42.0976Z" fill="#F9F8F3"/>
            <path d="M77.1211 105.137C75.7082 105.137 74.4164 104.256 73.8916 102.893L43.0897 18.1277L21.2499 16.765C19.3525 16.6448 17.9396 15.0417 18.0607 13.158C18.1818 11.2743 19.7966 9.87157 21.6939 9.9918L45.7945 11.5148C47.167 11.5949 48.3377 12.4767 48.7818 13.7592L79.18 97.4024L129.48 79.4073C131.256 78.766 133.235 79.6878 133.88 81.4512C134.526 83.2147 133.598 85.1785 131.822 85.8198L78.2918 104.977C77.8881 105.057 77.4845 105.137 77.1211 105.137Z" fill="#33495C"/>
            <path d="M37.1959 8.54894L17.7378 7.62714C15.5175 7.50691 13.5394 9.27035 13.4183 11.5147L13.2568 14.6007C13.1357 16.8451 14.912 18.809 17.1727 18.8891L36.6307 19.8109C38.8914 19.9311 40.8695 18.1677 40.9502 15.9233L41.1117 12.8373C41.2732 10.5929 39.4969 8.66917 37.1959 8.54894Z" fill="#E84C1C"/>
            <path d="M79.382 123.654C88.0772 123.654 95.1261 116.656 95.1261 108.023C95.1261 99.3906 88.0772 92.3926 79.382 92.3926C70.6868 92.3926 63.6379 99.3906 63.6379 108.023C63.6379 116.656 70.6868 123.654 79.382 123.654Z" fill="#33495C"/>
            <path d="M79.3818 114.275C82.8599 114.275 85.6795 111.476 85.6795 108.023C85.6795 104.57 82.8599 101.771 79.3818 101.771C75.9038 101.771 73.0842 104.57 73.0842 108.023C73.0842 111.476 75.9038 114.275 79.3818 114.275Z" fill="white"/>
            <defs>
                <linearGradient id="paint0_linear" x1="0.5" y1="69.9731" x2="153.491" y2="69.9731" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#BADCF5"/>
                    <stop offset="1" stopColor="#81C9F5"/>
                </linearGradient>
            </defs>
        </svg>
    );
}

export default TrolleyImage;
