import React from "react";
import classes from "./underline.module.scss";
import classNames from "classnames";
import buttonClasses from "../button/button.module.scss";
import {toCamelCase} from "src/helpers/additionalFuctions";

const Underline = function ({ children, color }) {
    return (
        <span className={classNames(classes["underline"],{
            [`${classes[`${toCamelCase(color)}`]}`]: color}) }>{children}</span>
    );
};

export default Underline;
