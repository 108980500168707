import React from "react";
import TimeWindowOption from "../components/time-window-option";
import {initTimeWindow, TimeModel} from "../constants/calendars";

class DeliveryTimeItems {
  constructor(data, isFiltered = false) {
    if (data) {
      this.time = initTimeWindow(
          new TimeModel(data.data.startHour || data.data.hour, data.data.startMinutes ,0),
          new TimeModel(data.data.endHour, data.data.endMinutes ,0),
          isFiltered
      );
      this.note = data.note || "";
      this.itemCost = data.cost;
      this.id = data.id;
    }
  }

  set cost(data) {
    this.itemCost = data;
  }

  get cost() {

    return this.itemCost
      ? `+ ${(this.itemCost / 100).toFixed(2)} €`
      : this.itemCost;
  }

  get data() {
    return {
      cost: this.itemCost || null,
    };
  }

  get label() {

    return  <TimeWindowOption cost={this.itemCost && (this.itemCost / 100).toFixed(2)}>{this.getLabel()}</TimeWindowOption>;
  }

  getLabel() {
    const to = `${this.time.to.hours}:${this.time.to.minutes === 0 ? '00' : this.time.to.minutes}`.padStart(5, '0');
    const from = `${this.time.from.hours}:${this.time.from.minutes === 0 ? '00' : this.time.from.minutes}`.padStart(5, '0');
    return `${from}-${to}`;
  }
}

export default DeliveryTimeItems;
