const EXTRA_PRICE_KE_KE = 5;
const EXTRA_PRICE_KE_CZ = 3;
const ExtraPrice_Pripadkova_Zone = 3;

const PRICE_LABLE_KE_CZ = 300;
const PRICE_LABLE_KE_KE = 500;

const BASE_PRICE_KE_KE = 700;
const BASE_PRICE_KE_CZ = 700;

const FORMATTED_PRICE_LABLE_KE_CZ = (PRICE_LABLE_KE_CZ/100).toFixed(2);
const FORMATTED_PRICE_LABLE_KE_KE = (PRICE_LABLE_KE_KE/100).toFixed(2);
export {
  BASE_PRICE_KE_CZ,
  BASE_PRICE_KE_KE,
  ExtraPrice_Pripadkova_Zone,
  FORMATTED_PRICE_LABLE_KE_CZ,
  PRICE_LABLE_KE_KE,
  PRICE_LABLE_KE_CZ,
  EXTRA_PRICE_KE_KE,
  EXTRA_PRICE_KE_CZ,
  FORMATTED_PRICE_LABLE_KE_KE,
};
