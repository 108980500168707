import React from "react";

function PackagesImage({ className }) {
    return (
        <svg width="152" height="130" viewBox="0 0 152 130" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M145.243 57.4311H101.852H95.8146C92.1009 57.4311 89.082 60.474 89.082 64.1637C89.082 67.8775 92.1248 70.8964 95.8146 70.8964H111.149C114.863 70.8964 117.881 73.9393 117.881 77.6291C117.881 81.3428 114.839 84.3617 111.149 84.3617H105.902C102.188 84.3617 99.169 87.4046 99.169 91.0944C99.169 94.8081 102.212 97.827 105.902 97.827H108.034C111.748 97.827 114.767 100.87 114.767 104.56C114.767 108.273 111.724 111.292 108.034 111.292H18.0177C14.3039 111.292 11.285 108.249 11.285 104.56C11.285 100.846 14.3279 97.827 18.0177 97.827H18.2812C21.995 97.827 25.0139 94.7842 25.0139 91.0944C25.0139 87.3806 21.971 84.3617 18.2812 84.3617H6.73266C3.04288 84.3857 0 81.3428 0 77.653C0 73.9393 3.04288 70.9204 6.73266 70.9204H27.0265C30.7402 70.9204 33.7591 67.8775 33.7591 64.1877C33.7591 60.474 30.7163 57.455 27.0265 57.455H20.6293H17.6103C13.8966 57.455 10.8777 54.4122 10.8777 50.7224C10.8777 47.0086 13.9206 43.9897 17.6103 43.9897H53.2863C57 43.9897 60.0189 40.9468 60.0189 37.2571C60.0189 33.5433 56.976 30.5244 53.2863 30.5244H47.1047C43.3909 30.5244 40.372 27.4815 40.372 23.7917C40.372 20.078 43.4149 17.0591 47.1047 17.0591H140.499C144.213 17.0591 147.232 20.102 147.232 23.7917C147.232 27.5055 144.189 30.5244 140.499 30.5244H138.247C134.533 30.5244 131.515 33.5673 131.515 37.2571C131.515 40.9708 134.557 43.9897 138.247 43.9897H145.243C148.957 43.9897 151.976 47.0326 151.976 50.7224C152 54.3882 148.957 57.4311 145.243 57.4311Z" fill="url(#paint0_linear)"/>
            <path d="M27.6015 104.608H18.5208" stroke="#D2E8F8" strokeWidth="4.79193" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M143.255 50.6985H134.054" stroke="#D2E8F8" strokeWidth="4.79193" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M80.265 125.141C77.7971 125.836 77.4856 126.195 76.8387 128.999C76.7429 129.406 76.3835 129.693 75.9762 129.693C75.5689 129.693 75.2095 129.406 75.1136 128.999C74.4907 126.195 74.1552 125.836 71.7114 125.141C71.328 125.045 71.0645 124.686 71.0645 124.303C71.0645 123.919 71.328 123.56 71.7114 123.464C74.1792 122.769 74.4907 122.41 75.1136 119.606C75.2095 119.199 75.5689 118.912 75.9762 118.912C76.3835 118.912 76.7429 119.199 76.8387 119.606C77.4617 122.41 77.7971 122.769 80.265 123.464C80.6483 123.56 80.9119 123.919 80.9119 124.303C80.9119 124.686 80.6483 125.021 80.265 125.141Z" fill="#FFC92F"/>
            <path d="M29.0388 19.479C25.4209 20.4853 24.9656 21.0124 24.0312 25.1335C23.9114 25.7085 23.3843 26.1398 22.7853 26.1398C22.1863 26.1398 21.6592 25.7325 21.5394 25.1335C20.605 21.0124 20.1497 20.4853 16.5318 19.479C15.9808 19.3352 15.5974 18.8081 15.5974 18.2331C15.5974 17.6581 15.9808 17.1549 16.5318 16.9872C20.1497 15.9809 20.605 15.4538 21.5394 11.3327C21.6592 10.7577 22.1863 10.3264 22.7853 10.3264C23.3843 10.3264 23.9114 10.7337 24.0312 11.3327C24.9656 15.4538 25.4209 15.9809 29.0388 16.9872C29.5898 17.131 29.9732 17.6581 29.9732 18.2331C29.9732 18.8081 29.5898 19.3113 29.0388 19.479Z" fill="#FFC92F"/>
            <path d="M127.082 7.42743C128.405 7.42743 129.478 6.35472 129.478 5.03146C129.478 3.70821 128.405 2.6355 127.082 2.6355C125.759 2.6355 124.686 3.70821 124.686 5.03146C124.686 6.35472 125.759 7.42743 127.082 7.42743Z" fill="#FFC92F"/>
            <path d="M111.053 26.0439H52.4956L45.0442 97.851H118.528L111.053 26.0439Z" fill="#3B5E8D"/>
            <path d="M76.6947 49.261H35.9873L30.812 97.8511H81.894L76.6947 49.261Z" fill="#FFB618"/>
            <path d="M119.798 26.0439H61.2408L53.7893 97.851H127.274L119.798 26.0439Z" fill="#427FEC"/>
            <path d="M85.4643 49.261H44.7568L39.5815 97.8511H90.6635L85.4643 49.261Z" fill="#FFC92F"/>
            <path d="M106.333 35.2685C105.111 35.2685 104.128 34.2861 104.128 33.0642V18.5926C104.128 11.0932 98.0187 4.98349 90.5194 4.98349C83.02 4.98349 76.9103 11.0932 76.9103 18.5926V33.0642C76.9103 34.2861 75.9279 35.2685 74.706 35.2685C73.4841 35.2685 72.5017 34.2861 72.5017 33.0642V18.5926C72.5017 8.67328 80.5761 0.598877 90.4954 0.598877C100.415 0.598877 108.489 8.67328 108.489 18.5926V33.0642C108.513 34.2861 107.531 35.2685 106.333 35.2685Z" fill="white" stroke="white" strokeWidth="1.19798" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M65.0982 79.1864C58.677 79.1864 53.4778 73.9632 53.4778 67.566V58.1259C53.4778 56.904 54.4601 55.9216 55.6821 55.9216C56.904 55.9216 57.8864 56.904 57.8864 58.1259V67.566C57.8864 71.5673 61.1449 74.8018 65.1222 74.8018C69.1234 74.8018 72.358 71.5433 72.358 67.566V58.1259C72.358 56.904 73.3403 55.9216 74.5623 55.9216C75.7842 55.9216 76.7666 56.904 76.7666 58.1259V67.566C76.7186 73.9872 71.5194 79.1864 65.0982 79.1864Z" fill="#33495C"/>
            <path d="M145.435 99.5043L135.636 89.7048L142.632 85.6556C143.183 85.3442 143.495 84.7452 143.447 84.1222C143.399 83.4993 142.992 82.9482 142.417 82.7086L111.245 70.2735C110.622 70.0339 109.927 70.1777 109.472 70.6329C109.017 71.0882 108.873 71.807 109.113 72.406L121.548 103.577C121.787 104.152 122.338 104.56 122.961 104.608C123.584 104.656 124.183 104.344 124.495 103.793L128.544 96.7969L138.343 106.596C138.655 106.908 139.062 107.076 139.517 107.076C139.949 107.076 140.38 106.908 140.691 106.596L145.483 101.804C146.082 101.205 146.082 100.151 145.435 99.5043Z" fill="white"/>
            <path d="M128.783 65.1222L125.046 67.662C124.087 68.3328 122.77 68.0693 122.099 67.1109C121.428 66.1525 121.691 64.8347 122.65 64.1639L126.388 61.6241C127.346 60.9533 128.664 61.2168 129.335 62.1752C129.981 63.1336 129.742 64.4514 128.783 65.1222Z" fill="white"/>
            <path d="M102.596 82.9484L98.8578 85.4881C97.8994 86.159 96.5816 85.8955 95.9108 84.9371C95.2399 83.9787 95.5035 82.6609 96.4618 81.99L100.2 79.4503C101.158 78.7794 102.476 79.043 103.147 80.0014C103.817 80.9598 103.554 82.2776 102.596 82.9484Z" fill="white"/>
            <path d="M101.589 69.4112C100.99 69.8185 100.176 69.9144 99.4807 69.5789L95.4076 67.6142C94.3534 67.1111 93.8982 65.8412 94.4253 64.787C94.9284 63.7328 96.1983 63.2775 97.2525 63.8047L101.326 65.7694C102.38 66.2725 102.835 67.5424 102.308 68.5966C102.14 68.932 101.877 69.1956 101.589 69.4112Z" fill="white"/>
            <path d="M112.802 61.768C112.515 61.9596 112.155 62.1034 111.772 62.1274C110.598 62.2232 109.591 61.3367 109.496 60.1627L109.16 55.6583C109.064 54.4842 109.951 53.4779 111.125 53.3821C112.299 53.2863 113.305 54.1728 113.401 55.3468L113.736 59.8512C113.784 60.6419 113.401 61.3607 112.802 61.768Z" fill="white"/>
            <defs>
                <linearGradient id="paint0_linear" x1="0" y1="64.1692" x2="151.991" y2="64.1692" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#BADCF5"/>
                    <stop offset="1" stopColor="#81C9F5"/>
                </linearGradient>
            </defs>
        </svg>
    );
}

export default PackagesImage;
