import React from "react";
import buttonClasses from "./button.module.scss";
import classNames from "classnames";
import { toCamelCase } from "src/helpers/additionalFuctions";

const Button = function ({
  children,
  className,
  type = "button",
  color,
  hover,
  sm,
  onClick,
  disabled,
  hidden,
  ...props
}) {


  
  return (
    <button
      className={classNames(buttonClasses.button, className, {
        [`${buttonClasses[`button__${toCamelCase(color)}`]}`]: color,
        [`${buttonClasses[`button__${toCamelCase(hover)}`]}`]: hover,
        [`${buttonClasses[`button__${toCamelCase(hidden)}`]}`]: hidden,
        [`${buttonClasses['button__sm']}`]: sm,
      })}
      onClick={onClick}
      disabled={disabled}
      type={type}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
