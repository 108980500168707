import React from "react";
import styles from "./next-slider-Btn.module.scss"
function ButtonNextSlider() {
    return (
        <div className={styles.sliderButton}>
            <svg   
            xmlns="http://www.w3.org/2000/svg"
            width="53"
            height="52"
            fill="none"
            viewBox="0 0 53 52"
        >
            <g filter="url(#filter0_d)">
                <circle cx="26.5" cy="22.5" r="22.5" fill="#fff"></circle>
            </g>
            <path
            
                stroke="silver"
                strokeWidth="2.547"
                d="M23.018 14.434l8.83 8.83-8.83 8.83"
            ></path>
            <defs>
                <filter
                    id="filter0_d"
                    width="51.792"
                    height="51.792"
                    x="0.604"
                    y="0"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dy="3.396"></feOffset>
                    <feGaussianBlur stdDeviation="1.698"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow"
                        result="shape"
                    ></feBlend>
                </filter>
            </defs>
        </svg>
        </div>
     
    );
}

export default ButtonNextSlider;
