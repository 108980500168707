import React from "react";
import moment from "moment";
import TimeWindowOption from "../components/time-window-option";
import {PRICE_LABLE_KE_CZ, PRICE_LABLE_KE_KE,BASE_PRICE_KE_KE, FORMATTED_PRICE_LABLE_KE_KE} from "./price";

export const today = moment();

export const isToday = (date) => {
    if(!date) {
        return false;
    }
    const option = {
        'hour': 0,
        'minute': 0,
        'second': 0,
    };
    const clonedToday = moment(today).set(option);
    const clonedAnotherDate = moment(date).set(option);
    return clonedToday.format('Y-m-d') === clonedAnotherDate.format('Y-m-d');
};

export class TimeModel {
    hours;
    minutes;
    seconds;
    constructor(hours = 0, minutes = 0, seconds = 0) {
        this.hours = hours;
        this.minutes = minutes;
        this.seconds = seconds;
    }

    setHours(hours) {
        this.hours = hours;
    }
}

export const initTimeWindow = (from = null, to = null, isFiltered = false) => {
    const currentHour = +today.format('H');
    if(!from || !to) {
        return {};
    }
    if(from.hours <= currentHour && currentHour < to.hours && to.hours - 2 > currentHour && isFiltered) {
        let startPoint = to.hours;
        while (true) {
            if (startPoint - 2 <= currentHour) {
                from.setHours(startPoint);
                break;
            } else {
                startPoint -= 2;
            }
        }

    }

    return {
        from, to
    };
};

export const MONDAY = 1;
export const TUESDAY = 2;
export const FRIDAY = 5;
export const THURSDAY = 4;
export const SATURDAY = 6;
export const SUNDAY = 0;

const KE_KE_DELIVERY_DAY_END = 21;
const START_WORKING_HOUR = 8;
const PERIOD_WORKING_HOURS = '08:00-21:00';

const WEEKDAYS = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];
const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];
const TYPES_LIST = [
    { label: "Day" },
    { label: "Week", active: true },
    { label: "Month" },
];

const TIME_WINDOWS_INTERVAL = 60;

const TIME_WINDOWS = [
    {
        label: <TimeWindowOption description="Kedykoľvek v časovom okne" free="v cene">08:00-21:00</TimeWindowOption>,
        data: {
            hour: 17,
        },
        type: "default",
        value: 1,
    },
    {
        label: <TimeWindowOption description="Kedykoľvek v časovom okne" free="v cene">08:00-16:30</TimeWindowOption>,
        data: {
            hour: 12,
            cost: PRICE_LABLE_KE_KE,
        },
        value: 2,
    },
    {
        label: <TimeWindowOption description="Kedykoľvek v časovom okne" free="v cene">12:30-21:00</TimeWindowOption>,
        data: {
            hour: 17,
            cost: 0,
            note: "description=\"Kedykoľvek v časovom okne\""
        },
        type: "default",
        value: 3,
    },
    {
        label: <TimeWindowOption cost={FORMATTED_PRICE_LABLE_KE_KE}>09:00-10:00</TimeWindowOption>,
        data: {
            hour: 9,
            cost: PRICE_LABLE_KE_KE,
        },
        value: 4,
    },
    {
        label: <TimeWindowOption cost={FORMATTED_PRICE_LABLE_KE_KE}>10:00-11:00</TimeWindowOption>,
        data: {
            hour: 10,
            cost: PRICE_LABLE_KE_KE,
        },
        value: 5,
    },
    {
        label: <TimeWindowOption cost={FORMATTED_PRICE_LABLE_KE_KE}>11:00-12:00</TimeWindowOption>,
        data: {
            hour: 11,
            cost: PRICE_LABLE_KE_KE,
        },
        value: 6,
    },
    {
        label: <TimeWindowOption cost={FORMATTED_PRICE_LABLE_KE_KE}>12:00-13:00</TimeWindowOption>,
        data: {
            hour: 12,
            cost: 300,
        },
        value: 7,
    },
    {
        label: <TimeWindowOption cost={FORMATTED_PRICE_LABLE_KE_KE}>13:00-14:00</TimeWindowOption>,
        data: {
            hour: 13,
            cost: PRICE_LABLE_KE_KE,
        },
        value: 8,
    },
    {
        label: <TimeWindowOption cost={FORMATTED_PRICE_LABLE_KE_KE}>14:00-15:00</TimeWindowOption>,
        data: {
            hour: 14,
            cost: PRICE_LABLE_KE_KE,
        },
        value: 9,
    },

    {
        label: <TimeWindowOption cost={FORMATTED_PRICE_LABLE_KE_KE}>15:00-16:00</TimeWindowOption>,
        data: {
            hour: 15,
            cost: PRICE_LABLE_KE_KE,
        },
        value: 10,
    },
    {
        label: <TimeWindowOption cost={FORMATTED_PRICE_LABLE_KE_KE}>16:00-17:00</TimeWindowOption>,
        data: {
            hour: 16,
            cost: PRICE_LABLE_KE_KE,
        },
        value: 11,
    },
    {
        label: <TimeWindowOption cost={FORMATTED_PRICE_LABLE_KE_KE}>17:00-18:00</TimeWindowOption>,
        data: {
            hour: 17,
            cost: PRICE_LABLE_KE_KE,
        },
        value: 12,
    },
];

const TIME_ITEMS_DURATION = 120;

const TIME_ITEMS = [
    {
        id: 1,
        time: "08:00-21:00",
        data: {
            startHour: 8,
            endHour: 21,
            hour: 17
        },
        type: "standard",
        note: "Kedykoľvek v časovom okne"
    },
    {
        id: 2,
        time: "08:00-16:30",
        data: {
            startHour: 8,
            hour: 14,
            endHour: 16,
            endMinutes: 30
        },
        type: "standard",
        note: "Kedykoľvek v časovom okne"
    },
    {
        id: 3,
        time: "12:30-21:00",
        data: {
            startHour: 12,
            startMinutes: 30,
            hour: 18,
            endHour: 21,
        },
        type: "standard",
        note: "Kedykoľvek v časovom okne"
    },
    {
        id: 4,
        time: "10:00-12:00",
        data: {
            hour: 10,
            endHour: 12,
        },
        cost: PRICE_LABLE_KE_KE,
    },
    {
        id: 5,
        time: "12:00-14:00",
        data: {
            hour: 12,
            endHour: 14,
        },
        cost: PRICE_LABLE_KE_KE,
    },
    {
        id: 6,
        time: "14:00-16:00",
        data: {
            hour: 14,
            endHour: 16,
        },
        cost: PRICE_LABLE_KE_KE,
    },
    {
        id: 7,
        time: "16:00-18:00",
        data: {
            hour: 16,
            endHour: 18,
        },
        cost: PRICE_LABLE_KE_KE,
        type: "inTwoDays",
        note: "Doručenie až o 2 dni",
    }
];

const KE_CZ_TIME_ITEMS = [
    {
        id: 1,
        time: "10:00-14:00",
        data: {
            startHour: 10,
            hour: 12,
            endHour: 14
        },
        type: "standard",
    },
    {
        id: 2,
        time: "10:00-12:00",
        data: {
            hour: 10,
            endHour: 12,
        },
        cost: PRICE_LABLE_KE_CZ,
    },
    {
        id: 3,
        time: "12:00-14:00",
        data: {
            hour: 12,
            endHour: 14,
        },
        cost: PRICE_LABLE_KE_CZ,
    },
    {
        id: 4,
        time: "14:00-18:00",
        data: {
            startHour: 14,
            hour: 16,
            endHour: 18,
        },
        note: "Doručenie až o 2 dni"
    },
];

const KE_CZ_TIME_GAP = 120;
const LAST_DELIVEY_HOUR = 16;
const LAST_PICKUP_HOUR = 14;
const LAST_WORKING_HOUR = 18;



export {
    WEEKDAYS,
    TYPES_LIST,
    MONTHS,
    TIME_WINDOWS,
    TIME_ITEMS,
    TIME_WINDOWS_INTERVAL,
    TIME_ITEMS_DURATION,
    KE_CZ_TIME_ITEMS,
    KE_CZ_TIME_GAP,
    KE_KE_DELIVERY_DAY_END,
    LAST_DELIVEY_HOUR,
    LAST_PICKUP_HOUR,
    LAST_WORKING_HOUR,
    START_WORKING_HOUR,
    PERIOD_WORKING_HOURS
};
